<template>
  <b-col md="4" style="margin: 50px auto">
    <b-card>
      <div slot="header" class="text-center">{{ $t("changeLanguage") }}</div>
      <b-form-group
        id="passInputGroup5"
        :label="$t('language')"
        label-for="language"
      >
        <b-input-group>
          <select id="language" v-model="selected" class="form-control">
            <option
              v-for="option in langs"
              v-bind:key="option.value"
              v-bind:value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </b-input-group>
      </b-form-group>

      <div class="form-group form-actions">
        <b-button
          type="button"
          @click="changeLanguage"
          size="sm"
          variant="success"
          >{{ $t("save") }}</b-button
        >
      </div>
    </b-card>
  </b-col>
</template>

<script>
import toast from "../../shared/toast-helper";
export default {
  name: "ResetLanguage",
  data() {
    return {
      selected: "tr-TR",
      langs: [{ text: "Turkish", value: "tr-TR" }],
    };
  },
  created() {
    var lang = localStorage.getItem("lang");
    if (!lang) {
      lang = navigator.language;
    }
    this.selected = lang;
  },
  methods: {
    changeLanguage() {
      if (this.selected) {
        localStorage.setItem("lang", this.selected);
        this.$i18n.locale = this.selected;
        toast.success();
        window.location.href = "/";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  max-width: 200px;
  margin: 10px 0px;
}
</style>